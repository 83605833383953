<template>
  <!-- 实验指南 -->
  <div class="main-contain">
    <div class="itemcontain">
      <div class="text" v-html="guide"></div>
    </div>
  </div>
</template>
<script>
export default {
  name: "Courseintroduction",
  components: {},
  data() {
    return {
      testimage: require("@/assets/test.png"),
      guide: "",
    };
  },
  methods: {
    initdata(data) {
      this.guide = data.guide;
    },
  },
};
</script>
<style lang="scss" scoped>
.main-contain {
  padding: 10px;
  background: #ffffff;
  // width: 100%;
  .itemcontain {
    // border: 1px solid red;
    margin-top: 20px;

    ::v-deep img {
      width: 90%;
    }

    .text {
      text-indent: 2em;
      line-height: 25px;
      margin: 0 auto;
      margin-top: 10px;
      font-size: 15px;
      font-weight: 400;
      color: #666666;
    }
  }
}
</style>
