import { render, staticRenderFns } from "./experimentalresources.vue?vue&type=template&id=68d0ab96&scoped=true"
import script from "./experimentalresources.vue?vue&type=script&lang=js"
export * from "./experimentalresources.vue?vue&type=script&lang=js"
import style0 from "./experimentalresources.vue?vue&type=style&index=0&id=68d0ab96&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "68d0ab96",
  null
  
)

export default component.exports