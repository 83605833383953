<template>
  <!-- 项目介绍 -->
  <div class="main-contain">
    <div class="text">
      <div v-html="brief"></div>
    </div>
  </div>
</template>
<script>
export default {
  name: "Courseintroduction",
  components: {},
  data() {
    return {
      brief: "",
    };
  },
  methods: {
    initdata(data) {
      this.brief = data.brief;
    },
  },
};
</script>
<style lang="scss" scoped>
.main-contain {
  padding: 10px;
  background: #ffffff;
  .text {
    width: 95%;
    text-indent: 2em;
    line-height: 25px;
    margin: 0 auto;
    margin-top: 10px;
    font-size: 15px;
    font-weight: 400;
    color: #666666;
  }
}
</style>
