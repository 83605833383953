import request from '@/utils/request'
//获取课程章节列表
export function getoutlinelist(params) {
  return request({
    url: '/portal/outline/get-list',
    method: 'GET',
    params
  })
}

//更新章节资料观看进度
export function currentProgress(data) {
  return request({
    url: '/portal/outline/update-study-progress',
    method: 'post',
    data
  })
}

//课程资料时间节点列表
export function getOutlineTimelist(params) {
  return request({
    url: '/portal/outline-file-time/get-list',
    method: 'GET',
    params
  })
}

//课程资料问答
export function getAnswer(params) {
  return request({
    url: '/portal/outline-file-time-stem-user/answer',
    method: 'GET',
    params
  })
}

//课程资料时间节点列表
export function getTimeVrList(params) {
  return request({
    url: '/portal/outline-file-time/get-vr-list',
    method: 'GET',
    params
  })
}